import { generateUniqueId } from 'src/common/utils/generateUniqueId';

export const SET_PAGE_LOAD_ID = 'SET_PAGE_LOAD_ID';
export const SET_BREADCRUMB_ID = 'SET_BREADCRUMB_ID';

export function setPageLoadId() {
  return {
    type: SET_PAGE_LOAD_ID,
    pageLoadId: generateUniqueId().toString(),
  };
}

export function setBreadcrumbId(options) {
  return {
    type: SET_BREADCRUMB_ID,
    breadcrumbId: options?.reset ? null : generateUniqueId().toString(),
  };
}
