import { lotame } from '@tunein/web-common';

export const SHARING_FROM_PLAYER = 'SHARING_FROM_PLAYER';
export const SHARING_FROM_PROFILE = 'SHARING_FROM_PROFILE';

export function sharingFromPlayer() {
  return (dispatch, getState) => {
    lotame.addInterests(getState()?.player?.nowPlaying);

    return dispatch({
      type: SHARING_FROM_PLAYER,
    });
  };
}

export function sharingFromProfile(guideItem) {
  return (dispatch, getState) => {
    const {
      player: { tunedGuideId, nowPlaying } = {},
    } = getState();
    const guideItemToUse =
      tunedGuideId === guideItem?.guideId ? nowPlaying : guideItem;

    lotame.addInterests(guideItemToUse);

    return dispatch({
      type: SHARING_FROM_PROFILE,
    });
  };
}
