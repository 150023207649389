export const APP_ERROR = 'APP_ERROR';
export const UPDATE_BRANCH_LINK = 'UPDATE_BRANCH_LINK';
export const SET_BRANCH_SHOWING_STATUS = 'SET_BRANCH_SHOWING_STATUS';
export const UPDATE_MW_BYPASS_COOKIE = 'UPDATE_MW_BYPASS_COOKIE';
export const SET_SHOW_PAIRING_SUCCESS_PAGE = 'SET_SHOW_PAIRING_SUCCESS_PAGE';
export const SET_ONETRUST_GDPR_DATA = 'SET_ONETRUST_GDPR_DATA';
export const SET_ONETRUST_DATA = 'SET_ONETRUST_DATA';
export const OPEN_ONETRUST_BANNER_ON_INIT = 'OPEN_ONETRUST_BANNER_ON_INIT';
export const SET_TOP_BANNER_HEIGHT = 'SET_TOP_BANNER_HEIGHT';
export const SET_BOTTOM_AD_BANNER_HEIGHT = 'SET_BOTTOM_AD_BANNER_HEIGHT';
export const SET_DO_NOT_ASK_TO_DELETE_DEVICE =
  'SET_DO_NOT_ASK_TO_DELETE_DEVICE';
export const ALLOW_AUTOPLAY = 'ALLOW_AUTOPLAY';
export const DISALLOW_AUTOPLAY = 'DISALLOW_AUTOPLAY';
export const UPDATE_BREAKPOINT = 'UPDATE_BREAKPOINT';
export const BRANCH_INITIALIZED = 'BRANCH_INITIALIZED';
export const BRAZE_INITIALIZED = 'BRAZE_INITIALIZED';
export const SET_DISCORD_STATE = 'SET_DISCORD_STATE';
export const SET_INFO_BANNER_MESSAGE = 'SET_INFO_BANNER_MESSAGE';

export function appError(error) {
  return {
    type: APP_ERROR,
    payload: error,
  };
}

export function updateBranchLink(branchUrl) {
  return {
    type: UPDATE_BRANCH_LINK,
    branchUrl,
  };
}

export function setBranchShowingStatus(isShowing) {
  return {
    type: SET_BRANCH_SHOWING_STATUS,
    isShowing,
  };
}

export function updateMWBypassCookie(value) {
  return {
    type: UPDATE_MW_BYPASS_COOKIE,
    value,
  };
}

export function setShowPairingSuccessPage(shouldShowPairingSuccessPage) {
  return {
    type: SET_SHOW_PAIRING_SUCCESS_PAGE,
    shouldShowPairingSuccessPage,
  };
}

export function setOneTrustData(oneTrustData) {
  return {
    type: SET_ONETRUST_DATA,
    oneTrustData,
  };
}

export function openOneTrustPreferenceCenter() {
  return setOneTrustData({ shouldDialogOpen: true });
}

export function openOneTrustBannerOnInit() {
  return {
    type: OPEN_ONETRUST_BANNER_ON_INIT,
  };
}

export function setTopBannerHeight(height) {
  return {
    type: SET_TOP_BANNER_HEIGHT,
    height,
  };
}

export function setBottomAdBannerHeight(height) {
  return {
    type: SET_BOTTOM_AD_BANNER_HEIGHT,
    height,
  };
}

export function setDoNotAskToDeleteDevice() {
  return {
    type: SET_DO_NOT_ASK_TO_DELETE_DEVICE,
  };
}

export function allowAutoplay() {
  return {
    type: ALLOW_AUTOPLAY,
  };
}

export function disallowAutoplay() {
  return {
    type: DISALLOW_AUTOPLAY,
  };
}

export function updateStoreBreakpoint(breakpoint) {
  return {
    type: UPDATE_BREAKPOINT,
    breakpoint,
  };
}

export function setIsBranchInitialized() {
  return {
    type: BRANCH_INITIALIZED,
  };
}

export function setIsBrazeInitialized() {
  return {
    type: BRAZE_INITIALIZED,
  };
}

export function setDiscordState(state) {
  return {
    type: SET_DISCORD_STATE,
    state,
  };
}

export function setInfoBannerMessage(message) {
  return {
    type: SET_INFO_BANNER_MESSAGE,
    message,
  };
}
